<template>
  <div class="home">
    <div class="fullWindowHeight" ref="imgTop" v-scroll="scrollChange">
      <div class="parallax fullWindowHeight" :style="{backgroundImage: `url(${require('@/assets/banco.png' )})`}">

      </div>
      <div class="innerTop">
      </div>
      <section id="arrowDown">
        <span></span>
      </section>
    </div>

    <div class="fullWindowHeight">
      <div style="height: 15vh; display: flex;">
        <div style="margin: auto auto 0;">
          <p
            class="is-size- has-text-weight-bold"
            style="font-size: 40px; font-family: Sans-Serif"
          >- WELCOME -</p>
        </div>
      </div>
      <div style="min-height: 85vh; width: 100%; display: flex;">
        <div class="columns" style="margin: auto;">
          <div class="column is-two-thirds">
            <div>
              <br />
              <p style="font-size: 1.18rem !important; max-width: 1100px;">
                <br />Hi! I'm Airton Esmerio, born in Santos, Sao Paulo, Brazil in February 1988.
                <br />I've been living in Ireland for the past 8 years where I had the chance to meet such warm people.
                <br />
                <br />
                <br />To keep my physical and mental health up, I keep on doing things that I love, such as dancing, travelling, playing voleyball, weight training, and learn new technologies.
                <br />
                <br />Part of my travels are going to Brazil twice a year to see my parents.
                <br />I've also been to other 18 countries, as far I can remember. I'm looking forward to many others.
                <br />
                <br />
                <br />Over 10 years of dancing classes I've learned Samba, Bolero, Salsa, Bachata, Forro, Zouk... I've not practiced some of them that much, but it's still fun to try it.
                <br />
                <br />
                <br />I've been working in IT world for 15 years. I've studied "Techology for computer networks" and nearly finished a post graduation on "Project management in software engineering".
                I've worked with hardware maintance, networks, customer support, teacher (MS office, Embarcadero Delphi),
                System analyst and sofware developer.
                <br />
                <br />So, every day trying to build a better version of myself.
              </p>
            </div>
            <br />
            <br />
            <br />
          </div>
          <div class="column is-one-third" style="display: flex;">
            <div style="margin: auto; border-radius: 40px; overflow: hidden;">
              <img :src="require('@/assets/miniUs.jpg')" alt="Airton e Juani" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fullWindowHeight backgroundGrey">
      <div style="height: 15vh; display: flex;">
        <div style="margin: auto auto 0;">
          <p
            class="is-size- has-text-weight-bold"
            style="font-size: 40px; font-family: Sans-Serif"
          >- MORE ABOUT ME -</p>
        </div>
      </div>
      <br />
      <br />
      <div class="aeCarousel">
        <b-carousel :indicator-inside="false">
          <b-carousel-item>
            <span class="image">
              <fade-image
                :src="require('@/assets/pic_0.jpg')" 
                :src-placeholder="require('@/assets/pic_0.min.jpg')" 
              />
            </span>
          </b-carousel-item>
          <b-carousel-item>
            <span class="image">
              <fade-image
                :src="require('@/assets/pic_1.jpg')" 
                :src-placeholder="require('@/assets/pic_1.min.jpg')" 
              />
            </span>
          </b-carousel-item>
          <b-carousel-item>
            <span class="image">
             <fade-image
                :src="require('@/assets/pic_2.jpg')" 
                :src-placeholder="require('@/assets/pic_2.min.jpg')" 
              />
            </span>
          </b-carousel-item>
          <b-carousel-item>
            <span class="image">
              <fade-image
                :src="require('@/assets/pic_3.jpg')" 
                :src-placeholder="require('@/assets/pic_3.min.jpg')" 
              />
            </span>
          </b-carousel-item>
          <b-carousel-item>
            <span class="image">
              <fade-image
                :src="require('@/assets/pic_4.jpg')" 
                :src-placeholder="require('@/assets/pic_4.min.jpg')" 
              />
            </span>
          </b-carousel-item>
          <b-carousel-item>
            <span class="image">
              <fade-image
                :src="require('@/assets/pic_5.jpg')" 
                :src-placeholder="require('@/assets/pic_5.min.jpg')" 
              />
            </span>
          </b-carousel-item>

          <template slot="indicators" slot-scope="props">
            <span class="al image" style="max-width: 20vh;">
              <fade-image
                v-if="props.i == 0" 
                :src="require('@/assets/pic_0.jpg')" 
                :src-placeholder="require('@/assets/pic_0.min.jpg')" 
              />
              <fade-image
                v-if="props.i == 1" 
                :src="require('@/assets/pic_1.jpg')" 
                :src-placeholder="require('@/assets/pic_1.min.jpg')" 
              />
              <fade-image
                v-if="props.i == 2" 
                :src="require('@/assets/pic_2.jpg')" 
                :src-placeholder="require('@/assets/pic_2.min.jpg')" 
              />
              <fade-image
                v-if="props.i == 3" 
                :src="require('@/assets/pic_3.jpg')" 
                :src-placeholder="require('@/assets/pic_3.min.jpg')" 
              />
              <fade-image
                v-if="props.i == 4" 
                :src="require('@/assets/pic_4.jpg')" 
                :src-placeholder="require('@/assets/pic_4.min.jpg')" 
              />
              <fade-image
                v-if="props.i == 5" 
                :src="require('@/assets/pic_5.jpg')" 
                :src-placeholder="require('@/assets/pic_5.min.jpg')" 
              />
            </span>
          </template>
        </b-carousel>

        <br />
        <br />
      </div>
    </div>

    <div style="max-width: 80%; margin: auto;">
      <div style="min-height: 15vh; width: 100%; display: flex;">
        <div style="margin: auto auto 0;">
          <p
            class="is-size- has-text-weight-bold"
            style="font-size: 40px; font-family: Sans-Serif"
          >- MY APPS -</p>
        </div>
      </div>
      <br />
      <br />
      <p
        class="is-size-4"
      >Here's where I try some ideas and test some coding. There's plenty tasks on my Trello to be done. 
      <span style="white-space: nowrap;">Some ideas are still WIP.</span></p>
      <p class="is-size-4">
        <a href="https://aelab.app">https://aelab.app</a>
      </p>
      <br />
      <br />
      <br />
      <div class="labImages">
        <div class="columns is-centered">
          <div class="column">
            <a href="https://aelab.app" target="_blank">
              <div class="box">
                <img src="@/assets/aeLab.png" alt="dices" />
                <p>AE Lab</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
    <div class="backgroundGrey">
      <div style="min-height: 15vh; width: 100%; display: flex;">
        <div style="margin: auto auto 0;">
          <p
            class="is-size- has-text-weight-bold"
            style="font-size: 40px; font-family: Sans-Serif"
          >- CONTACT -</p>
        </div>
      </div>
      <br />
      <br />
      <p class="is-size-4">aesmerio@gmail.com</p>
      <br />
      <div class="socialMedia">
        <a href="https://www.facebook.com/airton.esmerio/" target="_blank" rel="noreferrer" >
          <img src="@/assets//facebook.jpg"  alt="facebook logo"/>
        </a>
        <a href="https://www.instagram.com/airton.esmerio/" target="_blank" rel="noreferrer" >
          <img src="@/assets//instagram_1.jpg" alt="instagram logo"/>
        </a>
        <a href="https://www.linkedin.com/in/airton-esmerio/" target="_blank" rel="noreferrer" >
          <img src="@/assets//linkedin.png" alt="linkedIn logo"/>
        </a>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import FadeImage from '@/components/FadeImage.vue';

export default {
  name: 'home',
  components: {
    FadeImage
  },
  data() {
    return {
      imgOpacy: 0,
    }
  },
  methods: {
    scrollChange () {
      var scrollTop = window.scrollY
      var elHeight = this.$refs.imgTop.clientHeight

      if (scrollTop > elHeight)
        return
      var opacity = ((1 - (elHeight - scrollTop) / elHeight) * 0.95)
      opacity = 1 - (opacity)
      this.$refs.imgTop.setAttribute("style", `opacity: ${opacity}`)
    }
  },
  mounted(){
    window.aLoad()
    const spinner = document.querySelector(".spinner")
    spinner.parentNode.removeChild(spinner)
  }
}
</script>

<style  scoped>


[aLoad] { 
  background-image: none !important; 
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

.socialMedia img{
  width: 40px;
  margin: 10px;
}

.labImages img {
  width: 80px;
  margin: 10px;
}

.aeCarousel {
  height: 500px;
  max-height: 500px;
  display: table;
  margin: auto;
}

.backgroundGrey {
  background-color: #f2f2f2;
}

.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

.innerTop {
  position: absolute;
  top: 50%;
  margin-left: auto;
  font-size: 30px;
  color: white;
  margin-right: 50%;
}

/* .parallax1 {
  background-image: url("../assets/banco.png")
} */

.fullWindowHeight {
  min-height: 100vh; 
}

.labImages .column{
  max-width: 200px;
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#arrowDown {
  position: absolute;
  width: 100%;
  bottom: 60px;
}

#arrowDown span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}



@media screen and (max-width: 770px) {
  .labImages .column{
    max-width: 100%;
  }

  .labImages .column .box{
    max-width: 200px;
    margin: auto;
  }
}

</style>
